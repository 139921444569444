import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { Button, DataGrid, LoadIndicator, Toolbar } from "devextreme-react";
import { useStore } from "../../app/stores/store";
import { Column } from "devextreme-react/data-grid";
import { CommodityCodeSearchObj } from "../../app/models/commodityCodeSearchObj";
import { useState } from 'react';
import { CommodityCodeAutofill } from '../../app/models/commodityCodeAutofill';

interface Props{
    handleClose: (commodityCodeAdded: CommodityCodeAutofill | null) => void
}

export default function CommodityCodeSearch({handleClose}: Props){
    const { commodityCodeStore } = useStore()
    const [commodityCodes, setCommodityCodes] = useState(commodityCodeStore.commodityCodesAutofill)
    const [searchCommodityCode, setSearchCommodityCode] = useState('')
    const [searchCommodityDescription, setSearchCommodityDescription] = useState('')
    
    let formData: CommodityCodeSearchObj = {code: searchCommodityCode, description: searchCommodityDescription};

    const handleSearchCode = async() => {
        if(formData.code || formData.description)
        {
            setSearchCommodityCode(formData.code)
            setSearchCommodityDescription(formData.description)

            if(formData.code && !formData.description)
                await commodityCodeStore.getByCode(formData.code);
            else if(!formData.code && formData.description)
                await commodityCodeStore.getByDescription(formData.description);
            else
                await commodityCodeStore.getByBoth(formData.code, formData.description);

            setCommodityCodes(commodityCodeStore.commodityCodesAutofill)
        }
    }

    const handleCloseForm = () =>{
        handleClose(null)
    }

    const handleSelect = (cellData: any) =>{
        let selectedCommodityCode: CommodityCodeAutofill = {code: cellData.data.code, description: cellData.data.description}
        handleClose(selectedCommodityCode)
    }

    const renderButton = (cellData: any) => {
        return (
            <>
                <Button
                    icon="check"
                    width={40}
                    type="success"
                    stylingMode="contained"
                    onClick={() => handleSelect(cellData)}
                />
            </>
        )
    }

    if(commodityCodeStore.loading)
    {
        return <LoadIndicator className="organisations-load-indicator" />
    }

    return <Form formData={formData}>
            <GroupItem cssClass="cds-group-item" caption={'Search'} >
                <Item dataField='code' label={{text: "Code"}}  ></Item>
                <Item dataField='description' label={{text: "Description"}}  ></Item>
            </GroupItem>
            <GroupItem>
                <Button onClick={handleSearchCode} text={'Search'} />
                <Button onClick={handleCloseForm} text={'Cancel'} />
            </GroupItem>
            <GroupItem>
                <DataGrid dataSource={commodityCodes} keyExpr='code' onSelectionChanged={handleCloseForm} rowAlternationEnabled={true}>
                    <Column dataField='code' caption={'Code'} defaultSortIndex={0} defaultSortOrder="asc"></Column>
                    <Column dataField='description' caption={'Description'}></Column>
                    <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
                </DataGrid>
            </GroupItem>
        </Form>
}
