import { getCurrentDateISO } from '../common/utils/formatDate'
import { store } from '../stores/store'
import { CustomsCarrier } from './customsCarriers'
import { CustomsPort } from './customsPorts'
import { CustomsRoute } from './customsRoutes'

export interface TaskStatus {
    authorityType: string
    portClearanceId: string
    status: string
    channelStatus: string
    channelAction: string
    errors: string[]
    portName: string,
    portCountryCode: string,
}

export interface ChannelStatus {
    exitPortName: string
    exitPortCountryCode: string
    exitAuthorityType: string
    exitAuthorityNumber: string
    exitAuthorityMessage: string
    exitStatus: string
    entryPortName: string
    entryPortCountryCode: string
    entryAuthorityType: string
    entryAuthorityNumber: string
    entryAuthorityMessage: string
    entryStatus: string
    channelStatus: string
    channelAction: string
    errors: string[]
}

export interface PortClearanceSummary {
    id: string
    organisationId: string
    bucketId: string
    customerId?: string
    customerName: string
    transactionPrefix: string
    transactionId: number
    transactionPrefixId: string
    requestedDate: string
    requestedBy: string
    createdDate: string
    createdBy: string
    sailingDate: string
    status: string
    customsRouteId: string
    customsRouteName: string
    truckRegistration: string
    trailerRegistration: string
    containerRegistration: string
    notifyDriver: string
    entryAuthority: string
    entryAuthorityNumber: string
    entryStatus: string
    entryChannelStatus: string
    exitAuthority: string
    exitAuthorityNumber: string
    exitStatus: string
    exitChannelStatus: string
}

export interface PortClearance {
    id: string
    organisationId: string
    bucketId: string
    transactionPrefix: string
    transactionId: number
    requestedBy: string
    requestedDate: string
    createdBy: string
    createdDate: string
    updatedBy: string
    updatedDate: string
    status: string
    customsRoute: CustomsRoute
    carrier: CustomsCarrier
    notifyDriver: string
    driverEmail: string
    driverMobileNumber: string
    exitTask: PortClearanceTask
    entryTask: PortClearanceTask
}

export interface PortClearanceTask {
    id: string
    taskType: string
    authorityType: string
    isVehicleUnaccompanied: boolean
    isOwnVehicle: boolean
    sasMrnNumber: string | null
    emptyVehicle: boolean
    vehicleRegistration: string
    trailerRegistration: string | null
    containerRegistration: string | null
    contactName: string
    contactMobileNumber1: string
    contactMobileNumber2: string
    contactEmail: string
    status: string
    actionDate: string
    actionDateActual: string
    authorityNumber: string
    authorityMessage: string
    haulierType: string
    port: CustomsPort
    mrns: MRN[]
}

export interface MRN {
    id: string
    mrnNumber: string
    sasMrnNumber: string
    isTsad: boolean
    declarationType: string
    localReferenceNumber: string
    procedureCode: string
    nopWaiver: boolean
}

export class MRNForm implements MRN {
    id: string
    mrnNumber: string
    sasMrnNumber: string
    isTsad: boolean
    declarationType: string
    actionType: string
    localReferenceNumber: string
    procedureCode: string
    nopWaiver: boolean

    constructor(data: MRN, actionType: string) {
        this.id = data.id
        this.mrnNumber = data.mrnNumber
        this.isTsad = data.isTsad
        this.declarationType = data.declarationType
        this.sasMrnNumber = data.sasMrnNumber
        this.actionType = actionType
        this.localReferenceNumber = data.localReferenceNumber
        this.procedureCode = data.procedureCode
        this.nopWaiver = data.nopWaiver
    }
}

export class PortClearanceDataForm {
    id: string | null
    requestedBy: string | null
    requestedDate: string
    customsRouteId: string
    customsCarrierId: string
    notifyMethod: string
    notifyEmail: string
    notifyMobileNumber: string
    organisationId: string | null
    bucketId: string | null
    isVehicleUnaccompanied: boolean
    isOwnVehicle: boolean
    emptyVehicle: boolean
    vehicleRegistration: string
    trailerRegistration: string | null
    containerRegistration: string | null
    contactName: string
    actionDate: string
    actionDateActual: string | null
    haulierType: string
    exitTask: PortClearanceTaskDataForm
    entryTask: PortClearanceTaskDataForm
    mrns: MRNForm[] | null

    constructor(data: PortClearance | null) {
        if (data) {
            this.id = data.id
            this.requestedDate = data.requestedDate
            this.requestedBy = data.requestedBy
            this.notifyMethod = data.notifyDriver
            this.notifyEmail = data.driverEmail
            this.notifyMobileNumber = data.driverMobileNumber
            this.customsRouteId = data.customsRoute.id
            this.customsCarrierId = data.carrier.id
            this.organisationId = data.organisationId
            this.isVehicleUnaccompanied = data.exitTask.isVehicleUnaccompanied
            this.isOwnVehicle = data.exitTask.isOwnVehicle
            this.emptyVehicle = data.exitTask.emptyVehicle
            this.vehicleRegistration = data.exitTask.vehicleRegistration
            this.trailerRegistration = data.exitTask.trailerRegistration
            this.containerRegistration = data.exitTask.containerRegistration
            this.contactName = data.exitTask.contactName
            this.actionDate = data.exitTask.actionDate
            this.actionDateActual = data.exitTask.actionDateActual
            this.haulierType = (data.exitTask.haulierType) ? data.exitTask.haulierType : data.entryTask.haulierType
            this.bucketId = data.bucketId
            this.exitTask = new PortClearanceTaskDataForm(data.exitTask, "Exit")
            this.entryTask = new PortClearanceTaskDataForm(data.entryTask, "Entry")
            this.mrns = getDeclaredMrns(data.entryTask, data.exitTask)
        } else {
            this.id = null
            this.requestedDate = getCurrentDateISO()
            this.requestedBy = store?.userStore?.user?.email ?? ""
            this.notifyMethod = "None"
            this.notifyEmail = ""
            this.notifyMobileNumber = ""
            this.customsRouteId = store.clearanceStore?.customsRoutes ? store.clearanceStore.customsRoutes[0].id : getDefaultRouteId()
            this.customsCarrierId = store.clearanceStore?.customsCarriers ? store.clearanceStore.customsCarriers[0].id : "00000000-0000-0000-0000-000000000000"
            this.organisationId = null
            this.isVehicleUnaccompanied = false
            this.isOwnVehicle = false
            this.emptyVehicle = false
            this.vehicleRegistration = ''
            this.trailerRegistration = ''
            this.containerRegistration = ''
            this.contactName = ''
            this.actionDate = getCurrentDateISO()
            this.actionDateActual = null
            this.haulierType = "STANDARD"
            this.bucketId = null
            this.exitTask = new PortClearanceTaskDataForm(null, "Exit")
            this.entryTask = new PortClearanceTaskDataForm(null, "Entry")
            this.mrns = null
        }
    }
}



export class PortClearanceTaskDataForm {
    id: string | null
    taskType: string
    customsPortId: string | null
    authorityMessage: string | null

    constructor(task: PortClearanceTask | null, taskType: string) {
        this.taskType = taskType
        this.id = task?.id ?? null
        this.customsPortId = task?.port.id ?? getDefaultPortId(taskType)
        this.authorityMessage = task?.authorityMessage ?? ''
    }
}

function getDeclaredMrns(entry: PortClearanceTask, exit: PortClearanceTask) {
    let mrns: MRNForm[] = []

    if (entry.mrns) {
        entry.mrns.forEach(item => {
            mrns.push(new MRNForm(item, 'Import'))
        })
    }
    if (exit.mrns) {
        exit.mrns.forEach(item => {
            mrns.push(new MRNForm(item, 'Export'))
        })
    }

    if (mrns && mrns.length > 0)
        return mrns

    return null
}

function getDefaultRouteId() {
    if (store?.clearanceStore?.customsRoutes && store.clearanceStore.customsPorts) {
        const route = store.clearanceStore.customsRoutes[0]
        return route.id
    }
    return '00000000-0000-0000-0000-000000000000'
}

function getDefaultPortId(taskType: string) {
    if (store?.clearanceStore?.customsRoutes && store.clearanceStore.customsPorts) {
        const route = store.clearanceStore.customsRoutes[0]
        if (taskType === "Exit") {
            const port = store.clearanceStore.customsPorts.filter(port => port.countryIsoCode === route?.startRegionIsoCode)
            if (port)
                return port[0].id
        } else {
            const port = store.clearanceStore.customsPorts.filter(port => port.countryIsoCode === route?.endRegionIsoCode)
            if (port)
                return port[0].id
        }
    }
    return '00000000-0000-0000-0000-000000000000'
}