import { useEffect, useRef, useState } from "react"
import { FieldError } from "../../../../app/models/declarationCreate"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessageWithSequence, isFieldValidWithSequence } from "../../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';
import { GoodsItemIdentification } from "../../../../app/models/declarationGoodsItemDetail";
import CommodityCodeSearch from "../../CommodityCodeSearch";
import { CommodityCodeAutofill } from "../../../../app/models/commodityCodeAutofill";

interface Props{
    goodsItemNumber: number
    formData: GoodsItemIdentification | null;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityGoodsIdentification({goodsItemNumber, formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const [searchCode, setSearchCode] = useState(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }
    
    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const handleCloseSearch = (commodityCodeAdded: CommodityCodeAutofill | null) => {
        if(commodityCodeAdded && formData?.commodityCode)
        {
            formData.commodityCode.combinedNomenclatureCode = commodityCodeAdded.code;
            formData.commodityCode.description = commodityCodeAdded.description;
        }
        setSearchCode(false)
    }

    const validationRules: {
            grossMassRule: boolean
            commodityCodeDescriptionRule: boolean
            numberOfPiecesRule: boolean
            combinedNomenclatureCodeRule: boolean
            unDangerousRule: boolean
            netMassRule: boolean
        } = {
            grossMassRule: isFieldValidWithSequence("grossMass", goodsItemNumber ?? 0, fieldErrors),
            commodityCodeDescriptionRule: isFieldValidWithSequence("commodityCodeDescription", goodsItemNumber ?? 0, fieldErrors),
            numberOfPiecesRule: isFieldValidWithSequence("numberOfPieces", goodsItemNumber ?? 0, fieldErrors),
            combinedNomenclatureCodeRule: isFieldValidWithSequence("combinedNomenclatureCode", goodsItemNumber ?? 0, fieldErrors),
            unDangerousRule: isFieldValidWithSequence("unDangerous", goodsItemNumber ?? 0, fieldErrors),
            netMassRule: isFieldValidWithSequence("netMass", goodsItemNumber ?? 0, fieldErrors),
        };

        if(searchCode)
        {
            return <div className='html-blank-profile'>
            <div className='profile-container form-with-shadows-profile'>
                <CommodityCodeSearch handleClose={handleCloseSearch} />
            </div>
        </div>
        }
    
    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Goods Identification'} >
                    <Item dataField="grossMass" label={{text: "Gross Mass"}} editorType="dxNumberBox" visible={isFieldVisible("goodsIdentification.grossMass")} >
                        <CustomRule message={getErrorMessageWithSequence("grossMass", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.grossMassRule}} />
                    </Item>
                    <Item dataField="netMass" label={{text: "Net Mass"}} visible={isFieldVisible("goodsIdentification.netMass")}>
                        <CustomRule message={getErrorMessageWithSequence("netMass", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.netMassRule}} />
                    </Item>
                    <Item dataField="commodityCode.description" label={{text: "Goods Description"}} visible={isFieldVisible("goodsIdentification.commodityCode.description")}>
                        <CustomRule message={getErrorMessageWithSequence("commodityCodeDescription", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.commodityCodeDescriptionRule}} />
                    </Item>
                    <Item dataField="numberOfPieces" label={{text: "Num of packages"}} editorType="dxNumberBox" visible={isFieldVisible("goodsIdentification.numberOfPieces")}>
                        <CustomRule message={getErrorMessageWithSequence("numberOfPieces", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.numberOfPiecesRule}} />
                    </Item>
                    <Item dataField="commodityCode.combinedNomenclatureCode" label={{text: "Commodity Code"}} visible={isFieldVisible("goodsIdentification.commodityCode.combinedNomenclatureCode")} 
                            editorOptions={{buttons: [
                            {
                                name: 'search',
                                location: 'after',
                                options: {
                                    stylingMode: 'text',
                                    icon: 'search',
                                    onClick: () => setSearchCode(!searchCode),
                                },
                            },
                            ]}}
                        >
                        <CustomRule message={getErrorMessageWithSequence("combinedNomenclatureCode", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.combinedNomenclatureCodeRule}} />
                    </Item>
                    <Item dataField="unDangerous" label={{text: "UN Dangerous"}} visible={isFieldVisible("goodsIdentification.unDangerous")}>
                        <CustomRule message={getErrorMessageWithSequence("unDangerous", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.unDangerousRule}} />
                    </Item>
                </GroupItem>
    </Form>
}