import { CheckBox } from "devextreme-react";
import { DeclarationParties, FieldError } from "../../../app/models/declarationCreate"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { useEffect, useRef, useState } from "react";
import ActorEdit from "../ActorEdit";
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions";
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationParties;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityParties({formData, fieldErrors, isFieldVisible}: Props){
    const [actorEditMode, setActorEditMode] = useState(false);
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }
    
    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });
    
    const validationRules: {
        sellerNameRule: boolean
        sellerEoriRule: boolean
        buyerNameRule: boolean
        buyerEoriRule: boolean
        carrierNameRule: boolean
        carrierEoriRule: boolean
        importerNameRule: boolean
        importerEoriRule: boolean
        notifyPartyNameRule: boolean
        notifyPartyEoriRule: boolean
        declarantEoriRule: boolean
        representativeStatusRule: boolean
    } = {
      sellerNameRule: isFieldValid("sellerName", fieldErrors),
      sellerEoriRule: isFieldValid("sellerEori", fieldErrors),
      buyerNameRule: isFieldValid("buyerName", fieldErrors),
      buyerEoriRule: isFieldValid("buyerEori", fieldErrors),
      carrierNameRule: isFieldValid("carrierName", fieldErrors),
      carrierEoriRule: isFieldValid("carrierEori", fieldErrors),
      importerNameRule: isFieldValid("importerName", fieldErrors),
      importerEoriRule: isFieldValid("importerEori", fieldErrors),
      notifyPartyNameRule: isFieldValid("notifyPartyName", fieldErrors),
      notifyPartyEoriRule: isFieldValid("notifyPartyEori", fieldErrors),
      declarantEoriRule: isFieldValid("declarantEori", fieldErrors),
      representativeStatusRule: isFieldValid("representativeStatus", fieldErrors)
    };
    
    if(actorEditMode)
    {
        return <Form formData={formData}>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Parties'} visible={isFieldVisible("parties")}>
                    <CheckBox text='Expand' value={actorEditMode} onValueChanged={() => setActorEditMode(!actorEditMode)} />
                    <ActorEdit formData={formData.seller} actorCaption='Consignor' isRepresentative={false} fieldErrors={fieldErrors} />
                    <ActorEdit formData={formData.buyer} actorCaption='Consignee' isRepresentative={false} fieldErrors={fieldErrors} />
                    <ActorEdit formData={formData.carrier} actorCaption='Carrier' isRepresentative={false} fieldErrors={fieldErrors} />
                    <ActorEdit formData={formData.importer} actorCaption='Importer' isRepresentative={false} fieldErrors={fieldErrors} />
                    <ActorEdit formData={formData.notifyParty} actorCaption='Notify Party' isRepresentative={false} fieldErrors={fieldErrors} />
                    <ActorEdit formData={formData.declarant} actorCaption='Declarant' isRepresentative={false} fieldErrors={fieldErrors} />
                    <ActorEdit formData={formData.representative} actorCaption='Representative' isRepresentative={true} fieldErrors={fieldErrors} />
                </GroupItem>
                </Form>
    }

    return <div><Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'Parties'} >
        <GroupItem colSpan={2}><CheckBox text='Expand' value={actorEditMode} onValueChanged={() => setActorEditMode(!actorEditMode)} /></GroupItem>
            <Item dataField="seller.name" label={{text: "Consignor"}} visible={isFieldVisible("seller.name")}>
                <CustomRule message={getErrorMessage("consignorName", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.sellerNameRule}} />
            </Item>
            <Item dataField="seller.eori" label={{text: "Consignor Eori"}} visible={isFieldVisible("seller.eori")}>
                <CustomRule message={getErrorMessage("consignorEori", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.sellerEoriRule}} />
            </Item>
            <Item dataField="buyer.name" label={{text: "Consignee"}} visible={isFieldVisible("buyer.name")}>
                <CustomRule message={getErrorMessage("consigneeName", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.buyerNameRule}} />
            </Item>
            <Item dataField="buyer.eori" label={{text: "Consignee Eori"}} visible={isFieldVisible("buyer.eori")}>
                <CustomRule message={getErrorMessage("consigneeEori", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.buyerEoriRule}} />
            </Item>
            <Item dataField="carrier.name" label={{text: "Carrier"}} visible={isFieldVisible("carrier.name")}>
                <CustomRule message={getErrorMessage("carrierName", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.carrierNameRule}} />
            </Item>
            <Item dataField="carrier.eori" label={{text: "Carrier Eori"}} visible={isFieldVisible("carrier.eori")}>
                <CustomRule message={getErrorMessage("carrierEori", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.carrierEoriRule}} />
            </Item>
            <Item dataField="carrier.name" label={{text: "Importer"}} visible={isFieldVisible("importer.name")}>
                <CustomRule message={getErrorMessage("importerName", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.importerNameRule}} />
            </Item>
            <Item dataField="importer.eori" label={{text: "Importer Eori"}} visible={isFieldVisible("importer.eori")}>
                <CustomRule message={getErrorMessage("importerEori", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.importerEoriRule}} />
            </Item>
            <Item dataField="notifyParty.name" label={{text: "Notify Party"}} visible={isFieldVisible("notifyParty.name")}>
                <CustomRule message={getErrorMessage("notifyPartyName", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.notifyPartyNameRule}} />
            </Item>
            <Item dataField="notifyParty.eori" label={{text: "Notify Party Eori"}} visible={isFieldVisible("notifyParty.eori")}>
                <CustomRule message={getErrorMessage("notifyPartyEori", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.notifyPartyEoriRule}} />
            </Item>
            <Item dataField="declarant.eori" label={{text: "Declarant Eori"}} visible={isFieldVisible("declarant.eori")}>
                <CustomRule message={getErrorMessage("declarantEori", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.declarantEoriRule}} />
            </Item>
            <Item dataField="representative?.status" label={{text: "Status Code"}} visible={isFieldVisible("representative.status")}>
                <CustomRule message={getErrorMessage("representativeStatus", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.representativeStatusRule}} />
            </Item>
        </GroupItem>
    </Form></div>
}