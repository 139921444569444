import { GoodsItemDetail } from "./declarationGoodsItemDetail";
import { SafetyAndSecurityMessageInformation, SafetyAndSecurityReferences } from "./declarationSafetyAndSecurity";

export interface DeclarationRequest{
    organisationId: string;
    bucketId: string | null;
    authority: number;
    request: DeclarationDataRequest;
}

export interface DeclarationDataRequest{
    importDeclarationData: DecalarationData;
}

export interface DecalarationData{
    isImport: boolean;
    sentToAuthority: boolean;
    declarationType: string;
    additionalDeclarationType: string;
    authoritySystem: string;
    authoritySystemReference: string;
    authoritySystemSubmitted: boolean;
    totalNumberPackages: number;
    messageInformationEns: SafetyAndSecurityMessageInformation | null;
    references: DeclarationReference[] | null;
    referencesEns: SafetyAndSecurityReferences;
    totalPackagesRef: number;
    lrn: string;
    additionalInfo: DeclarationAdditionalInfo[];
    deferredPayment1: DeclarationDeferredPayment;
    deferredPayment2: DeclarationDeferredPayment;
    identificationOfWarehouse: string | null;
    authorisationHolder: DeclarationAuthorisationHolder[];
    referenceNumberUCR: string | null;
    parties: DeclarationParties;
    valuationInformationTaxes: DeclarationValuationInformationTaxes;
    places: DeclarationPlaces;
    customsOffices: DeclarationCustomsOffices;
    goodsIdentification: DeclarationGoodsIdentification;
    transportInformation: DeclarationTransportInformation;
    consignment: SafetyAndSecurityConsingment;
    otherDataElements: DeclarationOtherDataElements;
    summary: DeclarationSummary;
    goods: DeclarationGoods[];
    fieldErrors:  FieldError[];
    expressFields:  ExpressField[];
    templateId: string | null;
    bucket: DeclarationBucket | null;
}

export interface DeclarationBucket{
    id: string | null;
    customerId: string | null;
    customerName: string | null;
}

export interface FieldError{
    name: string
    error: any
}

export interface ExpressField{
    name: string
}

export class ExpressField{
    constructor(name:string){
        this.name = name
    }
}

export interface ReferencesEns{
    commercialReferenceNumber: string;
    additionalInformation: string;
    transportDocumentType: string;
    transportDocumentNumber: string;
    conveyanceReferenceNumber: string;
}

export interface MessageInformationEns{
    messageSender: string;
    messageRecipient: string;
    preparationDate: Date | null;
    preparationTime: Date | null;
    priority: number |null;
    messageIdentification: string;
    messageType: string;
    specificCircumstanceIndicator: string;
}

export interface DeclarationReference{
    category: string | null;
    type: string | null;
    referenceValue: string | null;
    itemID: string | null;
    lpcoExemptionCode: string | null;
}

export interface DeclarationAdditionalInfo{
    code: string | null;
    description: string | null;
}

export interface DeclarationDeferredPayment{
    identifier: string | null;
    category: number | null;
    type: string | null;
}

export interface DeclarationAuthorisationHolder{
    id: string | null;
    categoryCode: string | null;
}

export interface DeclarationParties{
    exporter: DeclarationActor | null;
    importer: DeclarationActor | null;
    seller: DeclarationActor | null;
    buyer: DeclarationActor | null;
    carrier: DeclarationActor | null;
    notifyParty: DeclarationActor | null;
    representative: DeclarationActorRepresentative | null;
    declarant: DeclarationActor | null;
    additionalSupplyChainActorId: string | null;
    additionalSupplyChainActorRole: string | null;
}

export interface DeclarationActor{
    eori: string | null;
    name: string | null;
    street: string | null;
    city: string | null;
    country: string | null;
    postcode: string | null;
    phone: string | null;
}

export interface DeclarationActorRepresentative extends DeclarationActor{
    status: string | null;
}

export interface DeclarationValuationInformationTaxes{
    incotermCode: string | null;
    countryCodeLocationName: string | null;
    unCode: string | null;
    exchangeRate: number | null;
    additionsAndReductions: DeclarationAdditionsAndReductions[] | null;
    totalAmountInvoiced: number | null;
    calculationOfTaxes: CalculationOfTaxes;
    methodOfPayment: string;
    personPayingCustomsDuty: string,
    personProvidingGuarantee: string,
    warehouseType: string,
    internalCurrency: string;
}

export interface CalculationOfTaxes{
    payableTaxAmount: PayableTaxAmount[];
    totalTaxCalculated: number | null;
    currency: string;
    itemPriceAmount: number | null;
    valuationIndicators: string;
    additionsAndReductions: DeclarationAdditionsAndReductions[] | null;
    totalAmountInvoiced: number | null;
}

export interface PayableTaxAmount{
    payableTaxAmount: number | null;
    currency: string;
    methodOfPayment: string;
    taxType: string;
}

export interface DeclarationAdditionsAndReductions{
    value: number | null;
    currency: string | null;
    type: string | null;
}

export interface DeclarationPlaces{
    countryOfDestinationCode: string | null;
    countryOfDispatchCode: string | null;
    locationOfGoods: DeclarationLocationOfGoods | null;
    placeOfLoading: string | null;
    arrivalAtFirstPlace: Date | null;
    countriesOfRouting: string[];
    placeOfUnloading: string | null;
    arrivalPort: string | null;
}

export interface DeclarationLocationOfGoods{
    additionalIdentifier: string | null;
    identificationOfLocation: string | null;
    typeOfLocation: string | null;
    country: string | null;
    qualifierOfTheIdentification: string | null;
}

export interface DeclarationCustomsOffices{
    presentationOffice: string | null;
    supervisingOffice: string | null;
    expectedArrival: Date | null;
    deeclarationPlace: string | null;
}

export interface DeclarationGoodsIdentification{
    grossMass: number | null;
}

export interface DeclarationTransportInformation{
    modeOfTransportAtBorder: string | null;
    container: boolean | null;
    containerList: string[] | null;
    modeOfTransportInland: string | null;
    identityMeansOfTransportOnArrival: number | null;
    nationalityOfTransportWhenCrossingBorder: string | null;
    identityOfMeansTransportOnArrival: string | null;
    identityOfBorderTransport: string | null;
    sealNumber: SealNumber[] | null;
}

export interface SealNumber{
    seal: string | null;
    containerId: string | null;
}

export interface DeclarationOtherDataElements{
    natureOfTransaction: number | null;
    guaranteeType: string[] | null;
    guaranteeList: DeclarationGuarantee[] | null;
}

export interface DeclarationGuarantee{
    reference: string | null;
    amount: number | null;
    accessCode: string | null;
    office: string | null;
    currency: string | null;
}

export interface DeclarationSummary{
    customsRequested: string | null;
    mrn: string | null;
    jobStatus: string | null;
    customs: string | null;
    haulierRequest: string | null;
    fromTo: string | null;
    haulierResponse: string | null;
    lastUpdate: string | null;
    authorityMessage: string | null;
}

export interface DeclarationGoods{
    itemDetail: GoodsItemDetail
}

export interface SafetyAndSecurityConsingment{
    carrier: DeclarationActor;
    freight: SafetyAndSecurityFreight;
    itinerary: SafetyAndSecurityItinerary[];
}

export interface SafetyAndSecurityFreight{
    methodOfPayment: string;
}

export interface SafetyAndSecurityItinerary{
    sequenceNumber: number;
    country: string;
}