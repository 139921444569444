import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { DecalarationData } from '../../app/models/declarationCreate'
import { useStore } from '../../app/stores/store'
import { useEffect } from 'react';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { UpdateBucket } from '../../app/models/declarationPackage';
import agent from '../../app/api/agent';

interface Props{
    formData: DecalarationData | null,
}

export default function CustomsSummary(props: Props){
    const { customerStore, organisationStore } = useStore()

    useEffect(() => {
        organisationStore.getOrganisationMe()
    }, [organisationStore]);


    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    useEffect(() => {
        customerStore.getCustomers(organisationId ?? '')
    }, [customerStore]);

    const handleCustomerChange = async(e: ValueChangedEvent) => {
        if(props.formData && props.formData.bucket && props.formData.bucket.id && props.formData.bucket.customerId && customerStore.customers)
        {
            let updateBucket: UpdateBucket = {
                id: props.formData.bucket.id, 
                customerId: props.formData.bucket.customerId, 
                customerName: getCustomerName(props.formData.bucket.customerId)
            };
            await agent.Buckets.update(updateBucket);
        }
    }

    function getCustomerName(customerId: string){
        if(customerStore.customers && props.formData && props.formData.bucket && props.formData.bucket.customerId)
        {
            return customerStore.customers.find(c => c.id == customerId)?.name ?? ''
        }

        return '';
    }

    return <Form formData={props.formData}><GroupItem cssClass="cds-group-item" colCount={2} caption={'Summary'}>
        <GroupItem colCount={1}>
            <Item 
                    dataField="bucket.customerId"
                    label={{text: "Customer"}}
                    editorType="dxSelectBox" 
                    editorOptions={{
                        items: customerStore.customers,
                        searchEnabled: true,
                        displayExpr: "name",
                        valueExpr: "id",
                        onValueChanged: handleCustomerChange
                    }}
            />
            <Item dataField="summary.mrn" label={{text: "MRN"}}/>
            <Item dataField="summary.jobStatus" label={{text: "Job status"}}/>
            <Item dataField="summary.customs" label={{text: "Customs"}}/>
        </GroupItem>
        <GroupItem cssClass="cds-group-item" colCount={2}>
            <Item dataField="summary.haulierRequest" label={{text: "Haulier Request"}}/>
            <Item dataField="summary.fromTo" editorOptions={{ readOnly: true}} label={{text: "From To"}}/>
            <Item dataField="summary.haulierResponse" label={{text: "Haulier Response"}}/>
            <Item dataField="summary.lastUpdate" editorOptions={{ readOnly: true}} label={{text: "Last Update"}}/>
        </GroupItem>
        <GroupItem colSpan={2}>
            <Item dataField="authoritySystem" label={{text: "Authority System"}} editorOptions={{ readOnly: true}}/>
            <Item dataField="summary.authorityMessage" editorType="dxTextArea" editorOptions={{ readOnly: true} } label={{text: "Authority Message"}}/>
        </GroupItem>
    </GroupItem>
    </Form>
}