import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { DeclarationGoods, FieldError } from '../../../app/models/declarationCreate'
import { DataGrid } from 'devextreme-react'
import { Column, CustomRule, Editing, Lookup } from 'devextreme-react/data-grid'
import { packageTypeOptions } from '../../../app/common/options/cdsImportDeclaration/packageTypeOptions'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValidWithSequence } from '../../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';
import { CommodityCodeAutofill } from '../../../app/models/commodityCodeAutofill'
import CommodityCodeSearch from '../CommodityCodeSearch'

interface Props{
    formData: DeclarationGoods;
    fieldErrors: FieldError[];
    isFieldVisible: (fieldName: string) => boolean;
}

export default function AddGoodsIdentification({formData, fieldErrors, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const [searchCode, setSearchCode] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
        currentValidateForm()
    });

    const handleCloseSearch = (commodityCodeAdded: CommodityCodeAutofill | null) => {
        if(commodityCodeAdded && formData?.itemDetail?.goodsIdentification?.commodityCode)
        {
            formData.itemDetail.goodsIdentification.commodityCode.combinedNomenclatureCode = commodityCodeAdded.code;
            formData.itemDetail.goodsIdentification.commodityCode.description = commodityCodeAdded.description;
        }
        setSearchCode(false)
    }

    const validationRules: {
        commodityCodeDescriptionRule: boolean
        combinedNomenclatureCodeRule: boolean
        taricCodeRule: boolean
        cusCodeRule: boolean
        cusTypeRule: boolean
        taricAdditionalCodeRule: boolean
        nationalAdditionalCodeRule: boolean
        supplementaryUnitsRule: boolean
        netMassRule: boolean
        grossMassRule: boolean
    } = {
        commodityCodeDescriptionRule: isFieldValidWithSequence("commodityCodeDescription", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        combinedNomenclatureCodeRule: isFieldValidWithSequence("combinedNomenclatureCode", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        taricCodeRule: isFieldValidWithSequence("taricCode", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        cusCodeRule: isFieldValidWithSequence("cusCode", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        cusTypeRule: isFieldValidWithSequence("cusType", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        taricAdditionalCodeRule: isFieldValidWithSequence("taricAdditionalCode", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        nationalAdditionalCodeRule: isFieldValidWithSequence("nationalAdditionalCode", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        supplementaryUnitsRule: isFieldValidWithSequence("supplementaryUnits", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        netMassRule: isFieldValidWithSequence("netMass", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors),
        grossMassRule: isFieldValidWithSequence("grossMass", formData.itemDetail.messageInformation.goodsItemNumber, fieldErrors)
    };

    if(searchCode)
    {
        return <div className='html-blank-profile'>
        <div className='profile-container form-with-shadows-profile'>
            <CommodityCodeSearch handleClose={handleCloseSearch} />
        </div>
    </div>
    }

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" caption={'Goods Identification'} visible={isFieldVisible("section-goods.goodsIdentification")}>
            <GroupItem cssClass="cds-group-item" caption={'Packaging'} visible={isFieldVisible("goods.goodsIdentification.packages")} >
                <DataGrid dataSource={formData.itemDetail.goodsIdentification.packages} >
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true}></Editing>
                    <Column dataField="type"><Lookup dataSource={packageTypeOptions} valueExpr={'code'} displayExpr={'description'} /></Column>
                    <Column dataField="numberPackages" dataType={'number'}></Column>
                    <Column dataField="shippingMarks"></Column>
                </DataGrid>
            </GroupItem>
            <GroupItem cssClass="cds-group-item" caption={'Commodity code'}>
                <GroupItem>
                    <Item dataField="itemDetail.goodsIdentification.commodityCode.description" label={{text: "Description of goods"}} visible={isFieldVisible("goods.goodsIdentification.commodityCode.description")}>
                        <CustomRule message={getErrorMessage("commodityCodeDescription", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.commodityCodeDescriptionRule}} />
                    </Item>
                    <Item dataField="itemDetail.goodsIdentification.commodityCode.combinedNomenclatureCode" label={{text: "Combined Nomenclature Code"}} visible={isFieldVisible("goods.goodsIdentification.commodityCode.combinedNomenclatureCode")}
                            editorOptions={{buttons: [
                            {
                                name: 'search',
                                location: 'after',
                                options: {
                                    stylingMode: 'text',
                                    icon: 'search',
                                    onClick: () => setSearchCode(!searchCode),
                                },
                            },
                      ]}}>
                        <CustomRule message={getErrorMessage("combinedNomenclatureCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.combinedNomenclatureCodeRule}} />
                    </Item>
                    <Item dataField="itemDetail.goodsIdentification.commodityCode.taricCode" label={{text: "TARIC Code"}} visible={isFieldVisible("goods.goodsIdentification.commodityCode.taricCode")}>
                        <CustomRule message={getErrorMessage("taricCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.taricCodeRule}} />
                    </Item>
                    <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
                </GroupItem>
                <GroupItem colCount={2}>
                    <Item dataField="itemDetail.goodsIdentification.commodityCode.cusCode" label={{text: "CUS Code"}} visible={isFieldVisible("goods.goodsIdentification.commodityCode.cusCode")}>
                        <CustomRule message={getErrorMessage("cusCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.cusCodeRule}} />
                    </Item>
                    <Item dataField="itemDetail.goodsIdentification.commodityCode.cusType" label={{text: "CUS Type"}} visible={isFieldVisible("goods.goodsIdentification.commodityCode.cusType")} >
                        <CustomRule message={getErrorMessage("cusType", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.cusTypeRule}} />
                    </Item>
                    <Item dataField="itemDetail.goodsIdentification.commodityCode.taricAdditionalCode" label={{text: "TARIC Additional Code"}} visible={isFieldVisible("goods.goodsIdentification.commodityCode.taricAdditionalCode")} >
                        <CustomRule message={getErrorMessage("taricAdditionalCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.taricAdditionalCodeRule}} />
                    </Item>
                    <Item dataField="itemDetail.goodsIdentification.commodityCode.nationalAdditionalCode" label={{text: "National additional code"}} visible={isFieldVisible("goods.goodsIdentification.commodityCode.nationalAdditionalCode")} >
                        <CustomRule message={getErrorMessage("nationalAdditionalCode", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.nationalAdditionalCodeRule}} />
                    </Item>
                </GroupItem>
            </GroupItem>
            <GroupItem colCount={3}>
                <Item dataField="itemDetail.goodsIdentification.supplementaryUnits" label={{text: "Supplementary id number"}} visible={isFieldVisible("goods.goodsIdentification.supplementaryUnits")} >
                    <CustomRule message={getErrorMessage("supplementaryUnits", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.supplementaryUnitsRule}} />
                </Item>
                <Item dataField="itemDetail.goodsIdentification.netMass" label={{text: "Net Mass (kg)"}} visible={isFieldVisible("goods.goodsIdentification.netMass")} >
                    <CustomRule message={getErrorMessage("netMass", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.netMassRule}} />
                </Item>
                <Item dataField="itemDetail.goodsIdentification.grossMass" label={{text: "Gross Mass (kg)"}} visible={isFieldVisible("goods.goodsIdentification.grossMass")} >
                    <CustomRule message={getErrorMessage("grossMass", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.grossMassRule}} />
                </Item>
                <GroupItem>{/*I have a blank GroupItem to keep last field line from overlapping the border*/}</GroupItem>
            </GroupItem>
        </GroupItem>
        </Form>
}