import { makeAutoObservable, runInAction } from "mobx";
import { CommodityCodeAutofill } from "../models/commodityCodeAutofill";
import agent from "../api/agent";

export default class CommodityCodeStore {
    commodityCodesAutofill: CommodityCodeAutofill[] | null = null;
    loading: Boolean = false;
    isSuccess: boolean = false;
    
    constructor() {
        makeAutoObservable(this)
    }

    getByCode = async (code: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;
            this.commodityCodesAutofill = null;

            const response = await agent.CommodityCodes.getByCode(code)
            runInAction(() => {
                this.isSuccess = response.isSuccess;
                if(response.isSuccess && response.data)
                    this.commodityCodesAutofill = response.data
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    };

    getByDescription = async (description: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;
            this.commodityCodesAutofill = null;

            const response = await agent.CommodityCodes.getByDescription(description)
            runInAction(() => {
                this.isSuccess = response.isSuccess;
                if(response.isSuccess && response.data)
                    this.commodityCodesAutofill = response.data
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    };

    getByBoth = async (code: string, description: string) => {
        try {
            this.loading = true;
            this.isSuccess = false;
            this.commodityCodesAutofill = null;

            const response = await agent.CommodityCodes.getByBoth(code, description)
            runInAction(() => {
                this.isSuccess = response.isSuccess;
                if(response.isSuccess && response.data)
                    this.commodityCodesAutofill = response.data
            })
        } catch (error) {
            runInAction(() => {this.isSuccess = false;})
            console.log(error)
        }
        finally
        {
            runInAction(() => {this.loading = false;})
        }
    };
}