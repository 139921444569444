import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { DecalarationData } from '../../app/models/declarationCreate'
import { useEffect, useRef, useState } from 'react'
import { getErrorMessage, isFieldValid } from '../../app/common/utils/declarationErrorFunctions'
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData
    isFieldVisible: (fieldName: string) => boolean
}

export default function GoodsIdentification({formData, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
       if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        grossMassRule: boolean
    } = {
        grossMassRule: isFieldValid("grossMass", formData.fieldErrors)
    };
    
    const handleGetErrorMessage = (fieldId: string) => {
        return getErrorMessage(fieldId, formData.fieldErrors);
    }

    return <Form ref={formRef} formData={formData} onContentReady={() => setIsFormReady(true)}>
        <GroupItem cssClass={'cds-group-item'} colCount={2} caption={'Goods Identification'} visible={isFieldVisible("section-goodsidentification")}>
        <Item dataField="goodsIdentification.grossMass" label={{text: "Gross Mass (kg)"}} visible={isFieldVisible("goodsIdentification.grossMass")}>
            <CustomRule message={handleGetErrorMessage("grossMass")} ignoreEmptyValue={false} validationCallback={() => {return validationRules.grossMassRule}} />
        </Item>
        <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
        <GroupItem>{/* This is so the CustomeRule message does not overlap with the group border line */}</GroupItem>
    </GroupItem>
    </Form>
}