import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { Button, LoadIndicator } from "devextreme-react";
import { CustomerFormValues, CustomerStatus, SupplierAdd, SupplierUpdate } from "../../app/models/customer/customer";
import { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { router } from "../../app/router/Routes";
import Form, { Item, GroupItem, Label, TabbedItem, Tab, RequiredRule, TabPanelOptions, CustomRule } from 'devextreme-react/form'
import { currencyOptions } from "../../app/common/options/currencyOptions";
import { countryOptions } from "../../app/common/options/countryOptions";
import { CustomerAddressFormValues } from "../../app/models/customer/personaAddress";
import { notifyMessage } from "../../app/common/utils/notify";
import { shippingOperatorTypeOptions } from "../../app/common/options/cdsImportDeclaration/shippingOperatorTypes";
import { ValueChangedEvent } from "devextreme/ui/text_box";

export default observer(function SupplierForm() {
    const { id } = useParams<{ id: string }>()
    const { supplierStore, organisationStore, declarationStore } = useStore()
    const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true)
    const [isEoriValid, setIsEoriValid] = useState<boolean>(true)
    const [isValidatingEori, setIsValidatingEori] = useState<boolean>(false)
    const formRef = useRef<Form>(null)
    
    const currentValidateForm = () => {
        if(!isLoadingForm && formRef.current && !isValidatingEori && !isEoriValid){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(!isValidatingEori)
          currentValidateForm()
    });
    
    const handleGetSupplier = async () => {		
        if(!id)
        {
            notifyMessage("warning", "Invalid supplier");
            handleFormClose();
        }
		{id && await supplierStore.getSupplier(id)}
		if (!supplierStore.isSuccess) {
            notifyMessage("warning", "Failed to get supplier");
            handleFormClose();
		}

      if(supplierStore.supplier)
      {
          setSupplier(new CustomerFormValues(supplierStore.supplier))
      }
	};

    useEffect(() => {
        organisationStore.getOrganisationMe();
        {id && handleGetSupplier()}
    }, [organisationStore, supplierStore, id]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : null;

    const iniSuppValue: CustomerFormValues = {
        ...new CustomerFormValues(),
        organisationId: organisationId ?? '',
        currencyIsoCode:  orgDefault?.defaultCurrencyIsoCode ?? "EUR",
        address: {
          ...new CustomerAddressFormValues(),
          countryIsoCode: orgDefault?.countryIsoCode ?? "IE"
        },
        billingAddress: {
          ...new CustomerAddressFormValues(),
          countryIsoCode: orgDefault?.countryIsoCode ?? "IE"
        }
      }
    
      const [supplier, setSupplier] = useState<CustomerFormValues>(iniSuppValue)

    if(supplierStore.loading)
        return <LoadIndicator className="organisations-load-indicator" />

    function handleFormSubmit() {
      if(id)
      {
        handleUpdateSupplier()
      }
      else
      {
        handleAddSupplier()
      }
    }

    const handleAddSupplier = async () => {
      let supplierCreate: SupplierAdd = {
        organisationId: supplier?.organisationId ?? '',
        code: supplier.code ?? '',
        name: supplier.name ?? '',
        eori: supplier.eori ?? '',
        address: supplier.address,
        billingAddress: supplier.billingAddress,
        contactPhoneNumber: supplierStore.supplier?.contactPhoneNumber ?? '',
        contactMobileNumber: supplierStore.supplier?.contactMobileNumber ?? '',
        contactName: supplierStore.supplier?.contactName ?? '',
        contactEmail: supplierStore.supplier?.contactEmail ?? '',
        contactWeb: supplierStore.supplier?.contactWeb ?? '',
        currencyIsoCode: supplier.currencyIsoCode,
        taxIdentifierLabel1: supplier.taxIdentifierLabel1,
        taxIdentifierLabel2: supplier.taxIdentifierLabel2,
        taxNumber1: supplier.taxNumber1,
        taxNumber2: supplier.taxNumber2,
        operatorType: supplier.operatorType ?? 'None'
      }
      
      await supplierStore.createSupplier(supplierCreate)
      if(supplierStore.isSuccess)
      {
        notifyMessage("success", "Created Supplier")
        handleFormClose()
      }
      else
      {
        notifyMessage("warning", "Failed to create Supplier")
      }
    }

    const handleUpdateSupplier = async() => {
      let supplierUpdate: SupplierUpdate = {
        id: supplier.id ?? '',
        code: supplier.code ?? '',
        name: supplier.name ?? '',
        eori: supplier.eori ?? '',
        address: {
          address1: supplier.address?.address1 ?? '',
          address2: supplier.address?.address2 ?? '',
          type: supplier.address?.type ?? '',
          name: supplier.address?.name ?? '',
          city: supplier.address?.city ?? '',
          countryIsoCode: supplier.address?.countryIsoCode ?? '',
          postcode: supplier.address?.postcode ?? '',
          status: supplier.address?.status ?? '',
          code: supplier.address?.code ?? '',
          region: supplier.address?.region ?? ''
        },
        billingAddress: {
          address1: supplier.billingAddress?.address1 ?? '',
          address2: supplier.billingAddress?.address2 ?? '',
          type: supplier.billingAddress?.type ?? '',
          name: supplier.billingAddress?.name ?? '',
          city: supplier.billingAddress?.city ?? '',
          countryIsoCode: supplier.billingAddress?.countryIsoCode ?? '',
          postcode: supplier.billingAddress?.postcode ?? '',
          status: supplier.billingAddress?.status ?? '',
          code: supplier.billingAddress?.code ?? '',
          region: supplier.billingAddress?.region ?? ''
        },
        contactPhoneNumber: supplierStore.supplier?.contactPhoneNumber ?? '',
        contactMobileNumber: supplierStore.supplier?.contactMobileNumber ?? '',
        contactName: supplierStore.supplier?.contactName ?? '',
        contactEmail: supplierStore.supplier?.contactEmail ?? '',
        contactWeb: supplierStore.supplier?.contactWeb ?? '',
        currencyIsoCode: supplier.currencyIsoCode,
        taxIdentifierLabel1: supplier.taxIdentifierLabel1,
        taxIdentifierLabel2: supplier.taxIdentifierLabel2,
        taxNumber1: supplier.taxNumber1,
        taxNumber2: supplier.taxNumber2,
        defaultTaxRate: supplier.defaultTaxRate?.id ?? '',
        operatorType: supplier.operatorType ?? 'None'
      }

      await supplierStore.updateSupplier(supplierUpdate)
      if(supplierStore.isSuccess)
        {
          notifyMessage("success", "Updated Supplier")
          handleFormClose()
        }
        else
        {
          notifyMessage("warning", "Failed to update Supplier")
        }
    }

    function handleCopyToBillingAddress() {
        supplier.billingAddress = supplier.address
    
        setSupplier(new CustomerFormValues(supplier))
    
        notify('Address copied to billing address', 'success')
      }

      function handleSaveButton(e: any) {
        var validation = e?.validationGroup?.validate()
        if (validation?.isValid) {
          handleFormSubmit()
        }
      }

      const handleEoriChanged = async(e: ValueChangedEvent) => {
          setIsEoriValid(false)
          if(supplier.eori)
          {
            setIsValidatingEori(true)
            await declarationStore.isEoriValid(supplier.eori)
            setIsEoriValid(declarationStore.isEoriValidResult ?? false) 
            setIsValidatingEori(false)
          }
        }

      const onTabChange = (e: any) => {
        if (e.itemData.title === 'Supplier') {
          var supplierName = document.getElementsByName("name")
          supplierName[0]?.focus()
        } else if (e.itemData.title === 'Address') {
          var supplierAddress = document.getElementsByName("address.code")
          supplierAddress[0]?.focus()
        } else if (e.itemData.title === 'Billing address') {
          var supplierBilling = document.getElementsByName("billingAddress.code")
          supplierBilling[0]?.focus()
        }
      }

      const onInitialized = () => {
        var elements = document.getElementsByName("name")
        elements[0]?.focus()
        setIsLoadingForm(false)
      }

      function handleFormClose() {
        supplierStore.supplier = null;
        router.navigate(`/suppliers`)
      }

      const colCountByScreen = {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2
      }
      
if(id && !supplierStore.loading && !supplierStore.isSuccess)
{
  notify('Failed to get Supplier')
  handleFormClose()
}

if(id && !supplierStore.loading && supplierStore.supplier && supplierStore.supplier.id != id)
{
  notify('Failed to get correct Supplier')
  handleFormClose()
}

if(!supplier.operatorType)
  supplier.operatorType = 'None'

    return <div className='content-block'>
    <div className={'content-block dx-card'} style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
      <div className={'form-avatar'} style={{ margin: '5px 0px 5px 5px' }}>
      </div>
      <h4 style={{ margin: '15px 5px 15px 30px' }}>{supplier.name ? supplier.name : "Create new supplier"}</h4>
    </div>
    <div className={'content-block'}>
      <Form
        id={'form'}
        ref={formRef}
        formData={supplier}
        labelLocation={'top'}
        showValidationSummary={true}
        validationGroup={'customerFormValidation'}
        tabIndex={0}
        onContentReady={onInitialized}
      >
        <TabbedItem >
          <TabPanelOptions deferRendering={false} tabIndex={0} onTitleClick={onTabChange} />
          <Tab title="Supplier" icon="card" colCount={2}  >
            <GroupItem caption="Info" colCountByScreen={colCountByScreen} >
              <Item dataField="name" caption={"Name"} isRequired={true} >
                <Label text="Name" />
                <RequiredRule message="Name is required" />
              </Item>
              <Item dataField="code" caption={"Code"}>
                <Label text="Account Code" />
              </Item>
              <Item dataField="eori" caption={"EORI"} data-test="eori" isRequired={true} editorOptions={{onValueChanged: handleEoriChanged}}>
                <Label text={(!isValidatingEori) ? "EORI" : "EORI Validating"} />
                <RequiredRule message="EORI is required" />
                <CustomRule message={"EORI is invalid"} ignoreEmptyValue={false} validationCallback={() => isEoriValid} />
              </Item>
              <Item
                dataField="currencyIsoCode"
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  items: currencyOptions,
                  searchEnabled: true,
                  displayExpr: "name",
                  valueExpr: "code",
                  placeholder: "Select a currency..."
                }}>
                <Label text="Billing Currency" />
              </Item>
              <Item dataField="status"
                editorType="dxSelectBox"
                text={"Status"}
                editorOptions={{
                  items: CustomerStatus,
                  searchEnabled: false,
                  displayExpr: "description",
                  valueExpr: "description",
                  placeholder: "Status..."
                }}>
                <Label text="Status" />
              </Item>
              <Item dataField="operatorType"
                editorType="dxSelectBox"
                text={"Operator Type"}
                editorOptions={{
                  items: shippingOperatorTypeOptions,
                  searchEnabled: false,
                  displayExpr: "description",
                  valueExpr: "name",
                  placeholder: "Operator Type..."
                }}>
                <Label text="Operator Type" />
              </Item>
              <Item>
                <Button
                  icon="check"
                  type="success"
                  stylingMode="contained"
                  validationGroup='customerFormValidation'
                  onClick={(e) => handleSaveButton(e)}
                />{' '}
                <Button
                  icon="close"
                  type="danger"
                  stylingMode="contained"
                  onClick={() => handleFormClose()}
                />
              </Item>
            </GroupItem>
            <GroupItem caption="Tax Info" colCountByScreen={colCountByScreen}>

              <Item dataField="taxIdentifierLabel1" caption={"Tax Label 1"}>
                <Label text="Tax Label 1" />
              </Item>
              <Item dataField="taxNumber1" caption={supplier?.taxIdentifierLabel1}>
                <Label text={supplier?.taxIdentifierLabel1} />
              </Item>
              <Item dataField="taxIdentifierLabel2" caption={"Tax Label 2"}>
                <Label text="Tax Label 2" />
              </Item>
              <Item dataField="taxNumber2" caption={supplier?.taxIdentifierLabel2}>
                <Label text={supplier?.taxIdentifierLabel2} />
              </Item>
            </GroupItem>
          </Tab>


          <Tab title='Address' icon="map">
            <GroupItem caption='Address' colCountByScreen={colCountByScreen} >
              <Item dataField="address.code" caption={"Code"} isRequired={true}>
                <Label text="Code" />
              </Item>
              <Item dataField="address.name" caption={"Name"} isRequired={true}>
                <Label text="Name" />
              </Item>
              <Item dataField="address.address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                <Label text="Address 1" />
                <RequiredRule message="Supplier Address is required" />
              </Item>
              <Item dataField="address.address2" caption={"Address 2"} colSpan={2}>
                <Label text="Address 2" />
              </Item>
              <Item dataField="address.city" title={"City"}>
                <Label text="City" />
              </Item>
              <Item dataField="address.region" title={"Region"}>
                <Label text="County/Region" />
              </Item>
              <Item dataField="address.postcode" text={"Postcode"}>
                <Label text="Postcode" />
              </Item>
              <Item
                dataField="address.countryIsoCode"
                editorType="dxSelectBox"
                text={"Country"}
                isRequired={true}
                editorOptions={{
                  items: countryOptions,
                  searchEnabled: true,
                  displayExpr: "name",
                  valueExpr: "isoCode",
                  placeholder: "Select a country..."
                }}>
                <Label text="Country" />
              </Item>
              <Item dataField="address.latitude" title={"Latitude"}>
                <Label text="Latitude" />
              </Item>
              <Item dataField="address.longitude" title={"Longitude"} colSpan={2} >
                <Label text="Longitude" />
              </Item>
              <Item dataField="address.notes" title={"Notes"} colSpan={2} >
                <Label text="Notes" />
              </Item>
              <Item>
                <Button
                  icon="check"
                  type="success"
                  stylingMode="contained"
                  validationGroup='customerFormValidation'
                  onClick={(e) => handleSaveButton(e)}
                />{' '}
                <Button
                  icon="close"
                  type="danger"
                  stylingMode="contained"
                  onClick={() => handleFormClose()}
                />
                {'   '}
                <Button
                  text="Copy to billing address"
                  type="default"
                  stylingMode="contained"
                  onClick={handleCopyToBillingAddress}
                  useSubmitBehavior={false}
                />
              </Item>
            </GroupItem>
          </Tab>


          <Tab title='Billing address' icon="money">
            <GroupItem caption='Address' colCountByScreen={colCountByScreen} >
              <Item dataField="billingAddress.code" caption={"Code"} isRequired={true}>
                <Label text="Code" />
              </Item>
              <Item dataField="billingAddress.name" caption={"Name"} isRequired={true}>
                <Label text="Name" />
              </Item>
              <Item dataField="billingAddress.address1" caption={"Address 1"} colSpan={2} isRequired={true}>
                <Label text="Address 1" />
                <RequiredRule message="Billing Address is required" />
              </Item>
              <Item dataField="billingAddress.address2" caption={"Address 2"} colSpan={2}>
                <Label text="Address 2" />
              </Item>
              <Item dataField="billingAddress.city" title={"City"}>
                <Label text="City" />
              </Item>
              <Item dataField="billingAddress.region" title={"Region"} >
                <Label text="County/Region" />
              </Item>
              <Item dataField="billingAddress.postcode" text={"Postcode"}>
                <Label text="Postcode" />
              </Item>
              <Item
                dataField="billingAddress.countryIsoCode"
                editorType="dxSelectBox"
                text={"Country"}
                isRequired={true}
                editorOptions={{
                  items: countryOptions,
                  searchEnabled: true,
                  displayExpr: "name",
                  valueExpr: "isoCode",
                  placeholder: "Select a country..."
                }}>
                <Label text="Country" />
              </Item>
              <Item dataField="billingAddress.latitude" title={"Latitude"}>
                <Label text="Latitude" />
              </Item>
              <Item dataField="billingAddress.longitude" title={"Longitude"} colSpan={2} >
                <Label text="Longitude" />
              </Item>
              <Item dataField="billingAddress.notes" title={"Notes"} colSpan={2} >
                <Label text="Notes" />
              </Item>
              <Item>
                <Button
                  icon="check"
                  type="success"
                  stylingMode="contained"
                  validationGroup='customerFormValidation'
                  onClick={(e) => handleSaveButton(e)}
                />{' '}
                <Button
                  icon="close"
                  type="danger"
                  stylingMode="contained"
                  onClick={() => handleFormClose()}
                />
              </Item>
            </GroupItem>
          </Tab>
        </TabbedItem>
      </Form>
    </div>
  </div>
})