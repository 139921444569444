import axios, { AxiosError, AxiosResponse } from "axios"
import notify from "devextreme/ui/notify"
import { Account, ApiClient, ApiClientForm, ApiClientUpdate, UpdateAccount } from "../models/account"
import { ChannelStatus, PortClearance, PortClearanceDataForm, PortClearanceSummary, TaskStatus } from "../models/clearances"
import {
    Certificate,
    Certificates,
    Credentials,
    GeneralId,
    NewCertificate,
    NewCredential,
    Nominal,
    Nominals,
    Organisation,
    OrganisationList,
    TaxRate,
    TaxRates,
    UpdateCertificate,
} from "../models/organisation"
import { PaginatedResult, PaginationRequest, Result } from "../models/result"
import { CreateUser, emailValidation, Token, UpdateProfile, User } from "../models/user"
import { router } from "../router/Routes"
import { store } from "../stores/store"
import { DecalarationData, DeclarationRequest } from "../models/declarationCreate"
import { DeclarationHeader } from "../models/declarationHeader"
import { UpdateDeclarationRequest } from "../models/declarationUpdate"
import { DeclarationSendToAuthority } from "../models/declarationSendToAuthority"
import { CopyDeclaration, CopyDeclarationV2 } from "../models/declarationCopy"
import { DelcarationIsLrnUniqueRequest } from "../models/declarationIsLrnUniqueRequest"
import { CustomsRoute } from "../models/customsRoutes"
import { CustomsCarrier } from "../models/customsCarriers"
import { CustomsPort } from "../models/customsPorts"
import { Customer, CustomerAdd, CustomerUpdate } from "../models/customer/customer"
import { Address, CreateAddress, UpdateAddress } from "../models/address"
import { AuthorityCustomsRoute } from "../models/authorityCustomsRoute"
import { AuthorityCustomsCarrier } from "../models/authorityCustomsCarrier"
import { AuthorityCustomsPort } from "../models/authorityCustomsPort"
import { DeclarationTemplateHeader } from "../models/declarationTemplateHeader"
import { DeclarationPackage, UpdateBucket } from "../models/declarationPackage"
import { DeclarationRoroRequest } from "../models/declarationRoro"
import { SubmitTssConsignment } from "../models/submitTssConsignment"
import { CommodityCodeAutofill } from "../models/commodityCodeAutofill"

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_URL

const responseBody = <T>(response: AxiosResponse<T>) => response.data

axios.interceptors.request.use((config) => {
    const token = store.commonStore.token
    if (token && config.headers)
        config.headers.Authorization = `Bearer ${token}`
    return config
})

axios.interceptors.response.use(
    async (response) => {
        if (process.env.NODE_ENV === "development") await sleep(1000)
        return response as AxiosResponse<Result<any>>
    },
    (error: AxiosError) => {
        const { data, status, config } = error.response as AxiosResponse
        console.log(error.response)
        switch (status) {
            case 400:
                if (
                    data.errors &&
                    config.method === "get" &&
                    data.errors.hasOwnProperty("id")
                ) {
                    router.navigate("/not-found")
                }
                if (data.errors) {
                    const modalStateErrors = []
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(data.errors[key])
                        }
                    }
                    throw modalStateErrors.flat()
                }
                if (data.error) {
                    throw data.error
                } else {
                    notify(data)
                }
                break
            case 401:
                notify("unauthorised")
                store.commonStore.deleteToken()
                router.navigate("/home-page")
                break
            case 403:
                notify("forbidden")
                break
            case 404:
                router.navigate("/not-found")
                break
            case 500:
                store.commonStore.setServerError(data)
                router.navigate("/server-error")
                break
        }
        return Promise.reject(error)
    }
)

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const Accounts = {
    currentAccount: () => requests.get<Result<Account>>("/v1/account/me"),
    updateAccount: (account: UpdateAccount) => requests.put<any>("/v1/account/me", account),
    getApiClient: () => requests.get<Result<ApiClient[]>>("/v1/account/apiclients"),
    createApiclient: (api: ApiClientForm) => requests.post<Result<any>>("/v1/account/apiclient", api),
    updateApiClient: (api: ApiClientUpdate) => requests.put<Result<any>>("/v1/account/apiclient", api)
}

const Users = {
    current: () => requests.get<Result<User>>("/v1/users/me"),
    updateProfile: (user: UpdateProfile) => requests.put<any>("/v1/users/me", user),
    create: (user: CreateUser) => requests.post<any>("/v1/account/signup", user),
    resendEmailValidation: (data: emailValidation) => requests.post<Result<any>>("/v1/users/resendEmailValidation", data),
    emailValidation: (token: Token) => requests.post<Result<any>>("/v1/users/validateEmail", token),
}

const PortClearances = {
    create: (form: PortClearanceDataForm) => requests.post<Result<any>>("/v2/clearances/portclearance", form),
    update: (form: PortClearanceDataForm) => requests.put<Result<any>>("/v2/clearances/portclearance", form),
    delete: (id: string) => requests.del<Result<any>>(`/v1/clearances/portclearance/${id}`),
    getStatus: (costumerId: string) => requests.get<Result<ChannelStatus>>(`/v1/clearances/portclearances/channel/status/${costumerId}`),
    getPortClearance: (portClearanceId: string) => requests.get<Result<PortClearance>>(`/v1/clearances/portclearance/${portClearanceId}`),
    getPagedPortClearanceSummary: (pagination: PaginationRequest) => requests.get<Result<PaginatedResult<PortClearanceSummary[]>>>(`/v1/clearances/portclearances?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`)
}

const Declarations = {
    create: (importDeclaration: DeclarationRequest) => requests.post<any>("/v1/declarations", importDeclaration),
    getAll: (organisationId: string) => requests.get<Result<PaginatedResult<DeclarationHeader[]>>>(`/v1/declarations?OrganisationId=${organisationId}`),
    get: (id: string) => requests.get<Result<DecalarationData>>(`/v1/declarations/${id}`),
    getStatusUpdate: (id: string) => requests.post<Result<string>>(`/v1/declarations/status-update?DeclarationId=${id}`, id),
    update: (updateDeclaration: UpdateDeclarationRequest) => requests.put<any>("/v1/declarations", updateDeclaration),
    sendToAuthority: (sendToAuthority: DeclarationSendToAuthority) => requests.post<any>('/v1/declarations/send-to-authority', sendToAuthority),
    copyDeclaration: (copyDeclaration: CopyDeclaration) => requests.post<any>('/v1/declarations/copy', copyDeclaration),
    copyDeclarationV2: (copyDeclaration: CopyDeclarationV2) => requests.post<any>('/v2/declarations/copy', copyDeclaration),
    delete: (id: string) => requests.del<Result<boolean>>(`/v1/declarations/${id}`),
    deleteAuthority: (id: string) => requests.del<Result<boolean>>(`/v1/declarations/authority/${id}`),
    isLrnUnique: (request: DelcarationIsLrnUniqueRequest) => requests.get<Result<boolean>>(`/v1/declarations/is-lrn-unique?LocalReferenceNumber=${request.localReferenceNumber}&OrganisationId=${request.organisationId}`),
    getAllTemplates: (organisationId: string) => requests.get<Result<PaginatedResult<DeclarationTemplateHeader[]>>>(`/v1/declarations/templates?OrganisationId=${organisationId}`),
    createTemplate: (id: string) => requests.post<Result<string>>(`/v1/declarations/templates?id=${id}`, id),
    updateTemplate: (updateDeclaration: UpdateDeclarationRequest) => requests.put<Result<string>>(`/v1/declarations/templates`, updateDeclaration),
    getTemplate: (id: string) => requests.get<Result<DecalarationData>>(`/v1/declarations/templates/${id}`),
    deleteTemplate: (id: string) => requests.del<Result<any>>(`/v1/declarations/templates/${id}`),
    sendTemplateToDeclaration: (id: string) => requests.post<Result<any>>(`/v1/declarations/templates/send-to-declarations?id=${id}`, id),
    getPackages: (organisationId: string) => requests.get<Result<PaginatedResult<DeclarationPackage[]>>>(`/v1/declarations/buckets?OrganisationId=${organisationId}`),
    getPackage: (id: string) => requests.get<Result<DeclarationPackage>>(`/v1/declarations/buckets/${id}`),
    createRoRo: (request: DeclarationRoroRequest) => requests.post<Result<any>>(`/v1/declarations/rollonrolloff`, request),
    submitTss: (request: SubmitTssConsignment) => requests.post<Result<any>>(`/v1/declarations/submit-tss`, request),
    getSfd: (request: SubmitTssConsignment) => requests.post<Result<any>>(`/v1/declarations/get-sfd-tss`, request),
    isEoriValid: (eori: string) => requests.get<Result<any>>(`/v1/declarations/eori-checker/${eori}`),
}

const Organisations = {
    getOrganisationMe: () => requests.get<Result<OrganisationList>>("/v1/organisations/me"),
    create: (organisation: Omit<Organisation, "id">) => requests.post<Result<any>>("/v1/organisations", organisation),
    update: (organisation: Organisation) => requests.put<Result<any>>("/v1/organisations", organisation),
    getCertificates: (organisationId: string) => requests.get<Result<Certificates>>(`/v1/organisations/certificates/${organisationId}`),
    createCertificate: (newCertificate: NewCertificate) => requests.post<Result<any>>("/v1/organisations/certificate", newCertificate),
    updateCertificate: (updateCert: UpdateCertificate) => requests.put<Result<any>>("/v1/organisations/certificate", updateCert),
    defaultCertificate: (defaultCert: GeneralId) => requests.put<Result<any>>("/v1/organisations/certificate/default", defaultCert),
    getCertificateId: (certificateId: string) => requests.get<Result<Certificate>>(`v1/organisations/certificate/${certificateId}`),
    deleteCertificate: (certificateId: string) => requests.del<Result<any>>(`v1/organisations/certificate/${certificateId}`),
    getCredentials: (organisationId: string) => requests.get<Result<Credentials>>(`/v1/organisations/credentials/${organisationId}`),
    deleteCredential: (credentialId: string) => requests.del<Result<Credentials>>(`/v1/organisations/credential/${credentialId}`),
    defaultCredential: (defaultCredential: GeneralId) => requests.put<Result<any>>("/v1/organisations/credential/default", defaultCredential),
    createCredential: (newCredential: NewCredential) => requests.post<Result<any>>("/v1/organisations/credential", newCredential),
    updateCredential: (newCredential: Credential) => requests.put<Result<any>>("/v1/organisations/credential", newCredential),
    getNominals: (organisationId: string) => requests.get<Result<Nominals>>(`/v1/organisations/nominals/${organisationId}`),
    createNominal: (newNominal: Omit<Nominal, "id">) => requests.post<Result<any>>("/v1/organisations/nominal", newNominal),
    updateNominal: (newNominal: Nominal) => requests.put<Result<any>>("/v1/organisations/nominal", newNominal),
    getTaxRates: (organisationId: string) => requests.get<Result<TaxRates>>(`/v1/organisations/taxRates/${organisationId}`),
    createTaxRate: (newTaxRate: Omit<TaxRate, "id">) => requests.post<Result<any>>("/v1/organisations/taxRate", newTaxRate),
    updateTaxRate: (newTaxRate: TaxRate) => requests.put<Result<any>>("/v1/organisations/taxRate", newTaxRate),
}

const Management = {
    getCustomsRoutes: () => requests.get<Result<CustomsRoute[]>>("/v1/management/customsRoutes"),
    getCustomsCarriers: () => requests.get<Result<CustomsCarrier[]>>("/v1/management/customsCarriers"),
    getCustomsPorts: () => requests.get<Result<CustomsPort[]>>("/v1/management/customsPorts"),
    getAuthorityCustomsRoutes: () => requests.get<Result<AuthorityCustomsRoute[]>>("/v1/management/authorityCustomsRoutes"),
    getAuthorityCustomsCarriers: () => requests.get<Result<AuthorityCustomsCarrier[]>>("/v1/management/authorityCustomsCarriers"),
    getAuthorityCustomsPorts: () => requests.get<Result<AuthorityCustomsPort[]>>("/v1/management/authorityCustomsPorts"),
    getReferenceData: () => requests.get<Result<any>>("/v1/management/referenceData"),
    getHmrcUrl: () => requests.get<Result<any>>("/v1/management/hmrcoauthredirect"),
}

const Customers = {
    getCustomers: (organisationId: string) => requests.get<Result<PaginatedResult<Customer[]>>>(`/v1/customers?OrganisationId=${organisationId}`),
    getCustomer: (customerId: string) => requests.get<Result<Customer>>(`/v1/customers/${customerId}`),
    updateCustomer: (updateCustomer: CustomerUpdate) => requests.put<Result<any>>("/v1/customers", updateCustomer),
    createCustomer: (newCustomer: CustomerAdd) => requests.post<Result<any>>("/v1/customers", newCustomer),
    deleteCustomer: (customerId: string) => requests.del<Result<Customer>>(`/v1/customers/${customerId}`),
}

const Suppliers = {
    getSuppliers: (organisationId: string) => requests.get<Result<PaginatedResult<Customer[]>>>(`/v1/suppliers?OrganisationId=${organisationId}`),
    getSupplier: (supplierId: string) => requests.get<Result<Customer>>(`/v1/suppliers/${supplierId}`),
    updateSupplier: (updateSupplier: CustomerUpdate) => requests.put<Result<any>>("/v1/suppliers", updateSupplier),
    createSupplier: (newSupplier: CustomerAdd) => requests.post<Result<any>>("/v1/suppliers", newSupplier),
    deleteSupplier: (supplierId: string) => requests.del<Result<Customer>>(`/v1/suppliers/${supplierId}`),
}

const Addresses = {
    getAll: (organisationId: string) => requests.get<Result<PaginatedResult<Address[]>>>(`/v1/address?OrganisationId=${organisationId}`),
    get: (addressId: string) => requests.get<Result<Address>>(`/v1/address/${addressId}`),
    update: (updateAddress: UpdateAddress) => requests.put<Result<any>>("/v1/address", updateAddress),
    create: (newAddress: CreateAddress) => requests.post<Result<any>>("/v1/address", newAddress),
    delete: (addressId: string) => requests.del<Result<any>>(`/v1/address/${addressId}`),
}

const Buckets = {
    get: (id: string) => requests.get<Result<DeclarationPackage>>(`/v1/buckets/${id}`),
    delete: (id: string) => requests.del<Result<any>>(`/v2/buckets/${id}`),
    update : (updateBucket: UpdateBucket) => requests.put<Result<any>>(`/v1/buckets`, updateBucket)
}

const CommodityCodes = {
    getByCode: (code: string) => requests.get<Result<CommodityCodeAutofill[]>>(`/v1/commoditycodes/code/${code}`),
    getByDescription: (description: string) => requests.get<Result<CommodityCodeAutofill[]>>(`/v1/commoditycodes/description/${description}`),
    getByBoth: (code: string, description: string) => requests.get<Result<CommodityCodeAutofill[]>>(`/v1/commoditycodes/both/${code}/${description}`),
}

const agent = {
    Accounts,
    Users,
    PortClearances,
    Organisations,
    Declarations,
    Management,
    Customers,
    Suppliers,
    Addresses,
    Buckets,
    CommodityCodes
}

export default agent
