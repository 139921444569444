export function DefaultDeclarationObject(orgId: string | null, isImport: boolean) 
{
    return {
    "organisationId": orgId && orgId || "",
    "bucketId": null,
    "authority": 0,
    "request": {
        "importDeclarationData": {
            "isImport": isImport,
            "sentToAuthority": false,
            "declarationType": (isImport) ? 'H1' : 'B1',
            "additionalDeclarationType": 'A',
            "authoritySystem": "",
            "authoritySystemReference": "",
            "authoritySystemSubmitted": false,
            "totalNumberPackages": 0,
            "messageInformationEns": {
                "messageSender": '', 
                "messageRecipient": '', 
                "preparationDate": null, 
                "preparationTime": null, 
                "priority": null,
                "messageIdentification": '', 
                "messageType": '', 
                "specificCircumstanceIndicator": '',
                "security": 0
            },
            "references": [],
            "referencesEns": {
                "commercialReferenceNumber": '',
                "additionalInformation": '',
                "conveyanceReferenceNumber": '',
                "transportDocumentNumber": '',
                "transportDocumentType": '',
                "localReferenceNumber": ''
            },
            "totalPackagesRef": 0,
            "lrn": "",
            "additionalInfo": [],
            "deferredPayment1": {
                "identifier": null,
                "category": null,
                "type": null
            },
            "deferredPayment2": {
                "identifier": null,
                "category": null,
                "type": null
            },
            "identificationOfWarehouse": null,
            "authorisationHolder" : [],
            "referenceNumberUCR": null,
            "parties": 
            {
                "exporter": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "importer": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "seller": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "buyer": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "representative": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null,
                    "status": null
                },
                "declarant": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "carrier": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "notifyParty": {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "additionalSupplyChainActorId": null,
                "additionalSupplyChainActorRole": null
            },
            "valuationInformationTaxes" : {
                "methodOfPayment": '',
                "personPayingCustomsDuty": '',
                "personProvidingGuarantee": '',
                "warehouseType": '',
                "internalCurrency": '',
                "incotermCode": null,
                "countryCodeLocationName": null,
                "unCode" : null,
                "exchangeRate": null,
                "additionsAndReductions": [],
                "totalAmountInvoiced": null,
                "calculationOfTaxes": {
                    "payableTaxAmount": [],
                    "totalTaxCalculated": null,
                    "currency": '',
                    "itemPriceAmount": null,
                    "valuationIndicators": '',
                    "additionsAndReductions": null,
                    "totalAmountInvoiced": null
                },
            },
            "places":{
                "countryOfDestinationCode": null,
                "countryOfDispatchCode": null,
                "locationOfGoods":{
                    "additionalIdentifier": null,
                    "identificationOfLocation": null,
                    "typeOfLocation": null,
                    "country": null,
                    "qualifierOfTheIdentification": null
                },
                "placeOfLoading": null,
                "arrivalAtFirstPlace": null,
                "countriesOfRouting": [],
                "placeOfUnloading": '',
                "arrivalPort": ''
            },
            "customsOffices": {
                "presentationOffice": null,
                "supervisingOffice": null,
                "deeclarationPlace": '',
                "expectedArrival": null
            },
            "goodsIdentification": {
                "grossMass": null
            },
            "transportInformation": {
                "modeOfTransportAtBorder": null,
                "container": null,
                "containerList" : [],
                "modeOfTransportInland": null,
                "identityMeansOfTransportOnArrival": null,
                "nationalityOfTransportWhenCrossingBorder": null,
                "identityOfMeansTransportOnArrival": null,
                "identityOfBorderTransport": null,
                "sealNumber": []
            },
            "consignment": {
                "carrier": 
                {
                    "eori": null,
                    "name": null,
                    "street": null,
                    "city": null,
                    "country": null,
                    "postcode": null,
                    "phone": null
                },
                "freight": {
                    "methodOfPayment": ''
                },
                itinerary: []
            },
            "otherDataElements": {
                "natureOfTransaction" : null,
                "guaranteeType" : [],
                "guaranteeList" : []
            },
            "summary": {
                "customsRequested": null,
                "mrn": null,
                "jobStatus": null,
                "customs": null,
                "haulierRequest": null,
                "fromTo": null,
                "haulierResponse": null,
                "lastUpdate": null,
                "authorityMessage": null
            },
            "bucket": {
                "id": null,
                "customerId": null,
                "customerName": null
            },
            "goods": [],
            "fieldErrors" : [],
            "expressFields": [],
            "templateId": null
        }
    }
  }
};