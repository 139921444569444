import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import { DecalarationData, DeclarationRequest, FieldError } from "../models/declarationCreate"
import { DeclarationHeader } from "../models/declarationHeader";
import { UpdateDeclarationRequest } from "../models/declarationUpdate";
import { DeclarationSendToAuthority } from "../models/declarationSendToAuthority";
import { CopyDeclaration, CopyDeclarationV2 } from "../models/declarationCopy";
import { DelcarationIsLrnUniqueRequest } from "../models/declarationIsLrnUniqueRequest";
import { convertUtcToLocalTime } from "../common/utils/formatDate";
import { DeclarationTemplateHeader } from "../models/declarationTemplateHeader";
import { DeclarationPackage } from "../models/declarationPackage";
import { DeclarationRoroRequest } from "../models/declarationRoro";
import { SubmitTssConsignment } from "../models/submitTssConsignment";

export default class DeclarationStore {
    declarations: DeclarationHeader[]  = [];
    declaration: DecalarationData | null = null;
    declarationTemplates: DeclarationTemplateHeader[] = [];
    declarationTemplate: DecalarationData | null = null;
    declarationPackages: DeclarationPackage[] = [];
    declarationPackage: DeclarationPackage | null = null;
    isLrnUniqueResult: boolean | null = false;
    isEoriValidResult: boolean | null = false;
    loading: Boolean = false;
    isSuccess: boolean = false;
    error: any = null;

    constructor() {
        makeAutoObservable(this)
    }
    
    getStatusUpdate = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.getStatusUpdate(id)
                runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    sendToAuthority = async (declarationToSend: DeclarationSendToAuthority) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.sendToAuthority(declarationToSend)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    copyDeclaration = async (declarationToCopy: CopyDeclaration) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.copyDeclaration(declarationToCopy)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    copyDeclarationV2 = async (declarationToCopy: CopyDeclarationV2) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.copyDeclarationV2(declarationToCopy)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    delete = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.delete(id)
            runInAction(() => {this.isSuccess = response.isSuccess});
        } catch (error) {
            runInAction(() => {this.isSuccess = false});
            console.error(error)
        }
        finally {
            runInAction(() => {this.loading = false});
        }
    }

    deleteAuthority = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.deleteAuthority(id)
            runInAction(() => {this.isSuccess = response.isSuccess});
        } catch (error) {
            runInAction(() => {this.isSuccess = false});
            console.error(error)
        }
        finally {
            runInAction(() => {this.loading = false});
        }
    }

    isLrnUnique = async (request: DelcarationIsLrnUniqueRequest) => {
        this.loading = true;
        try
        {
            const response = await agent.Declarations.isLrnUnique(request)
            runInAction(() => {this.isSuccess = response.isSuccess; this.isLrnUniqueResult = response.data});
        }
        catch(error){
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {this.loading = false});
        }
    }

    isEoriValid = async(eori: string) => {
        this.loading = true;
        try
        {
            const response = await agent.Declarations.isEoriValid(eori)
            runInAction(() => {this.isSuccess = response.isSuccess; this.isEoriValidResult = response.data});
        }
        catch(error){
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {this.loading = false});
        }
    }

    createTemplate = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.createTemplate(id)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    createDeclarationFromTemplate = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.sendTemplateToDeclaration(id)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    createRollOnRollOff = async (request: DeclarationRoroRequest) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            const response = await agent.Declarations.createRoRo(request)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    setDeclarations = (declarations: DeclarationHeader[]) => {
        var declarationsWithLocalTime = declarations.map(d => {
            return {
                ...d,
                createdDate: convertUtcToLocalTime(d.createdDate),
                updatedDate: convertUtcToLocalTime(d.updatedDate),
                requestedDate: convertUtcToLocalTime(d.requestedDate)
            }
        })

        this.declarations = declarationsWithLocalTime;
    }

    setDeclarationTemplates = (declarations: DeclarationTemplateHeader[]) => {
        var declarationsWithLocalTime = declarations.map(d => {
            return {
                ...d,
                createdDate: convertUtcToLocalTime(d.createdDate),
                updatedDate: convertUtcToLocalTime(d.updatedDate)
            }
        })

        this.declarationTemplates = declarationsWithLocalTime;
    }

    setDeclaration = (declaration: DecalarationData | null) => {
        if(declaration)
        {
            if(declaration.consignment == null)
            {
                declaration.consignment = {
                    carrier: {
                        eori: null,
                        name: null,
                        street: null,
                        city: null,
                        country: null,
                        postcode: null,
                        phone: null
                    },
                    freight: {methodOfPayment: ''},
                    itinerary: []
                }
            }

            if(declaration.summary.lastUpdate)
                declaration.summary.lastUpdate = convertUtcToLocalTime(declaration.summary.lastUpdate)
        }

        this.declaration = declaration
    }

    setDeclarationTemplate = (declaration: DecalarationData | null) => {
        if(declaration)
        {
            if(declaration.consignment == null)
            {
                declaration.consignment = {
                    carrier: {
                        eori: null,
                        name: null,
                        street: null,
                        city: null,
                        country: null,
                        postcode: null,
                        phone: null
                    },
                    freight: {methodOfPayment: ''},
                    itinerary: []
                }
            }
        }

        this.declarationTemplate = declaration
    }

    setError(error: any) {
        this.error = error
    }

    setDeclarationPackages = (declarations: DeclarationPackage[]) => {
        var declarationsWithLocalTime = declarations.map(d => {
            return {
                ...d,
                createdDate: convertUtcToLocalTime(d.createdDate),
                updatedDate: convertUtcToLocalTime(d.updatedDate),
                requestedDate: convertUtcToLocalTime(d.requestedDate)
            }
        })

        this.declarationPackages = declarationsWithLocalTime;
    }

    setDeclarationPackage = (declaration: DeclarationPackage | null) => {
        if(declaration)
        {
            declaration.createdDate = convertUtcToLocalTime(declaration.createdDate)
            declaration.updatedDate = convertUtcToLocalTime(declaration.updatedDate)
            declaration.requestedDate = convertUtcToLocalTime(declaration.requestedDate)
        }
        
        this.declarationPackage = declaration;
    }

    submitToTss = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            let requestSubmitTss : SubmitTssConsignment = {declarationId: id};
            const response = await agent.Declarations.submitTss(requestSubmitTss)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    getSfd = async (id: string) => {
        this.loading = true;
        this.isSuccess = false;
        try
        {
            let requestSubmitTss : SubmitTssConsignment = {declarationId: id};
            const response = await agent.Declarations.getSfd(requestSubmitTss)
            runInAction(() => {this.isSuccess = response.isSuccess})
        } catch (error) {
            runInAction(() => {this.isSuccess = false})
            console.error(error)
        }
        finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
